import type { OrderMessage } from '@esp/orders/types';

const ACTION_SCOPE = '[Quote]';

export namespace CustomerPortalQuoteActions {
  export class GetQuote {
    static readonly type = `${ACTION_SCOPE} Get Quote`;

    constructor(readonly quoteId: string | number) {}
  }

  export class SubmitChangeRequest {
    static readonly type = `${ACTION_SCOPE} Submit Change Request`;

    constructor(
      readonly projectId: number,
      readonly quoteId: number,
      readonly messages: OrderMessage[]
    ) {}
  }

  export class ApproveQuote {
    static readonly type = `${ACTION_SCOPE} Approve Quote`;

    constructor(
      readonly projectId: number,
      readonly quoteId: number
    ) {}
  }
}
